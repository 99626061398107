html {
  min-height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(180deg, #6f6acb, #012466);
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.cvss-card {
    min-height: 335px;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 1em;
    text-align: center;
    -webkit-transform: scale(.9);
            transform: scale(.9);
    transition: 500ms cubic-bezier(0,.5,.4,1.5);
    box-shadow: 0 10px 15px rgba(0,0,0,.5);
    cursor: pointer;
    border: solid 5px transparent;
}

.cvss-card:hover {
    -webkit-transform: scale(1);
            transform: scale(1);
}

.cvss-card h2 {
    font-size: 1em;
    text-transform: uppercase;
    color: #5955a3;
}

.cvss-card > img {
    width: 70%;
    margin: auto;
}

a {
    text-decoration: none !important;
}

a:hover > .cvss-card {
    border: solid 5px #f1c750;
}
body {
  margin: 0;
}

* {
  box-sizing: border-box;
}

.container {
  padding-top: 100px;
}

.cvss-title {
  color: #fff;
  width: 100%;
  text-align: center;
}

.cvss-subtitle {
  color: #fff;
  width: 100%;
  text-align: center;
}

.particles canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}

@media all and (max-width: 1199.5px) {
  .container {
    padding-top:0;
  }
}
