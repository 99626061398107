body {
  margin: 0;
}

* {
  box-sizing: border-box;
}

.container {
  padding-top: 100px;
}

.cvss-title {
  color: #fff;
  width: 100%;
  text-align: center;
}

.cvss-subtitle {
  color: #fff;
  width: 100%;
  text-align: center;
}

.particles canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}

@media all and (max-width: 1199.5px) {
  .container {
    padding-top:0;
  }
}