.cvss-card {
    min-height: 335px;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 1em;
    text-align: center;
    transform: scale(.9);
    transition: 500ms cubic-bezier(0,.5,.4,1.5);
    box-shadow: 0 10px 15px rgba(0,0,0,.5);
    cursor: pointer;
    border: solid 5px transparent;
}

.cvss-card:hover {
    transform: scale(1);
}

.cvss-card h2 {
    font-size: 1em;
    text-transform: uppercase;
    color: #5955a3;
}

.cvss-card > img {
    width: 70%;
    margin: auto;
}

a {
    text-decoration: none !important;
}

a:hover > .cvss-card {
    border: solid 5px #f1c750;
}